import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByLatLng,
    getLatLng
} from "react-places-autocomplete";

import Nav from "../components/Nav";
import StepControlls from "../components/StepControlls";
import BreadCrumb from "../components/BreadCrumb";
import CustomSelect from "../components/CustomSelect";
import { connect } from 'react-redux';
import Jobin from 'jobin-client';
import "../styles/service-location.css";
import { jobPrice } from '../store/actions/jobAction';
import { jobAddress } from '../store/actions/serviceAction';

import mapsMarker from "../assets/maps-marker.svg";
import mapsMarkerGreen from "../assets/maps-marker-green.svg";
import mapsMarkerPelayo from "../assets/maps-marker-pelayo.svg";
import mapsMarkerRed from "../assets/summary-publish.svg";
import {getParameterByName} from "../utils/formatter";

let _map = null;
let _maps = null;
let _area = null;

const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["es", "pt", "fr"] }
};

const Marker = () => (
    <div className="maps-marker">
        <img src={getParameterByName("business") == "ZF5WZAbvQ6" ? mapsMarkerRed : getParameterByName("business") == "kqJtDU5D9b" ? mapsMarkerGreen: getParameterByName("business") == "Uo1ncXDbA2" ? mapsMarkerPelayo: mapsMarker} alt="marker" />
    </div>
);

class ServiceLocation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            mapsKey: this.props.mapsKey,
            center: this.props.center,
            zoom: this.props.zoom,
            address: this.props.address,
            addressSelected: this.props.addressSelected,
            error: this.props.error,
            disabledBtn: this.props.disabledBtn,
            nextPage: null,
            locationSelected:null,
            promotionOptions:this.props.job.locate.promotion_data,
            isActiveLocation:false,
            isActiveDetail:false,
            assetSelected:null
        };
    }

    checkLocation = () => {
        if (navigator.geolocation)
            navigator.geolocation.getCurrentPosition(this.updateByGeoLoc);
    };

    updateByGeoLoc = position => {
        geocodeByLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }).then(results => {
            if (this._isMounted) {
                this.setState({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: 16,
                    address: results[0].formatted_address,
                    addressSelected: true,
                    disabledBtn: false
                });
            }
            // draw circle area
            if (_area) _area.setMap(null);
            if (_maps) {
                _area = new _maps.Circle({
                    strokeColor: "#202020",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillOpacity: 0,
                    map: _map,
                    center: this.state.center,
                    radius: 450
                });
                _area.setMap(_map);
            }
        });
    };

    handleChange = address => {
        this.setState({ address });
        this.setState({
            addressSelected: false,
            disabledBtn: true
        });
        if (_area) _area.setMap(null);
    };
    selectedLocation = locationSelected => {
     
        this.setState({
            locationSelected: locationSelected
        });


        if (this._isMounted) {
            this.setState({
                center: {
                    lat: locationSelected.location.latitude,
                    lng: locationSelected.location.longitude
                },
                zoom: 16
            });
            if (_area) _area.setMap(null);
            if (_maps) {
                _area = new _maps.Circle({
                    strokeColor: "#202020",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillOpacity: 0,
                    map: _map,
                    center: this.state.center,
                    radius: 450
                });
                _area.setMap(_map);
            }
        }
    };
    selectedAsset=(asset)=>{
        if (this._isMounted) {
            this.setState({
                address: this.state.locationSelected.formattedData,
                assetSelected:asset.id,
                addressSelected: true,
                disabledBtn: false
            });
        }
    }
    handleActiveCustomSelect =  (id)=>{
        switch (id) {
            case "location":
                 this.setState({
                    isActiveLocation: !this.state.isActiveLocation,
                    isActiveDetail: false,
                });
                break;
            case "detail":
                 this.setState({
                    isActiveLocation: false,
                    isActiveDetail: !this.state.isActiveDetail,
                });
                break;
        
            default:
                break;
        }
    }
 

    handleSelect = address => {
        const latLngPromise = geocodeByAddress(address).then(results => {
            // update address state and then return for further computations
            if (this._isMounted) {
                this.setState({
                    address: results[0].formatted_address,
                    addressSelected: true,
                    disabledBtn: false
                });
            }
            return getLatLng(results[0]);
        });
        latLngPromise
            .then(latLng => {
                if (this._isMounted) {
                    this.setState({
                        center: {
                            lat: latLng.lat,
                            lng: latLng.lng
                        },
                        zoom: 16
                    });
                }
                // draw circle area
                if (_area) _area.setMap(null);
                if (_maps) {
                    _area = new _maps.Circle({
                        strokeColor: "#202020",
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillOpacity: 0,
                        map: _map,
                        center: this.state.center,
                        radius: 450
                    });
                    _area.setMap(_map);
                }
            })
            .catch(error => console.error("Error", error));
    };

    apiIsLoaded = (map, maps) => {
        _map = map;
        _maps = maps;
    };

    update = async (childData) => {
        this.props.mixpanel.track("Paso 5 completado");
        this.props.jobAddress(this.state.address)
        // push state to parent to save the content
        this.props.update(childData);
        const { status, result: guild } = await Jobin.Guild.get(this.props.subCategory || this.props.job.category.categoryId);
        const fixedPrice = guild && guild.get("Price") ? guild.get("Price").fixed : false;
        let priceAmount = null;

        if(fixedPrice){
            const subPostalCode = 28;
            if(fixedPrice[subPostalCode]){
                let data = {
                    amount: fixedPrice[subPostalCode].user
                }
                this.props.jobPrice(data);
                priceAmount = fixedPrice[subPostalCode].user;
            } else if (fixedPrice.default) {
                let data = {
                    amount: fixedPrice.default.user
                }
                this.props.jobPrice(data);
                priceAmount = fixedPrice.default.user;
            } else {
                let data = {
                    amount: null
                }
                this.props.jobPrice(data);
                priceAmount = null;
            }
        }
    };

    componentDidMount() {
        this.setState({
            nextPage: 'price'
        })
        if (
            !(this.props.location.state && "inner" in this.props.location.state)
        )
        Jobin.Client.logOut().then(result => {
        });

        this._isMounted = true;

        if (this.props.address === "") this.checkLocation();
    }

    componentDidUpdate(){
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { mapsKey, center, zoom, address, addressSelected,locationSelected,promotionOptions } = this.state;
        return (
            <div id="service-location" className="section">
                <Nav />
                <div className="margin-top-72px">
                    <BreadCrumb
                        history={this.props.history}
                        location={this.props.location}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{zIndex:9999}}>
                                <h4 className="title">
                                    ¿Donde necesitas el servicio?
                                </h4>
                                <p className="hidden-md hidden-sm hidden-xs">
                                    Selecciona la ubicacion donde deseas que se realice el servicio.
                                </p>
                                
                                <div className="content">
                                    {promotionOptions && <CustomSelect 
                                                            id="location" 
                                                            title="Selecciona la ubicacion de la residencia" 
                                                            options={promotionOptions} 
                                                            setIsActive={(id)=>this.handleActiveCustomSelect(id)} 
                                                            isActive={this.state.isActiveLocation} 
                                                            selectedOption={this.selectedLocation}  
                                                        />}
                                    {locationSelected &&  <CustomSelect     
                                                            id="detail" 
                                                            title="Selecciona los detalles de la ubicacion" 
                                                            options={locationSelected.assets}  
                                                            setIsActive={(id)=>this.handleActiveCustomSelect(id)} 
                                                            isActive={this.state.isActiveDetail} 
                                                            selectedOption={this.selectedAsset}
                                                        />}
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-4">
                                <div className="maps">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: mapsKey
                                        }}
                                        center={center}
                                        zoom={zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) =>
                                            this.apiIsLoaded(map, maps)
                                        }
                                    >
                                        {addressSelected && (
                                            <Marker
                                                lat={center.lat}
                                                lng={center.lng}
                                            />
                                        )}
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.nextPage 
                    ?
                    <StepControlls
                        history={this.props.history}
                        location={this.props.location}
                        page={this.props.step}
                        disabled={this.state.disabledBtn}
                        next={this.state.nextPage}
                        nextTrigger={!addressSelected}
                        nextTitle="Siguiente"
                        prev="picture"
                        cState={this.state}
                        update={this.update}
                    />
                    : 
                    null
                }
                
                <img
                    src="https://pixel.mathtag.com/event/img?mt_id=1443638&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                    width="1"
                    height="1"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isMutua: state.businessCodeReducer.isMutua,
        subCategory: state.jobReducer.category.subCategoryId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobPrice: (data) => dispatch(jobPrice(data)),
        jobAddress: (address) => dispatch(jobAddress(address))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLocation);
